import {
    Button,
    Typography,
    Card,
    Input,
    Textarea,
    Checkbox,
  } from "@material-tailwind/react";
import CustomButton from "./Btn/CustomButton";
import groupImg  from "../img/groupDos1.webp";
  
  export function ContactSection5() {
    return (
      <section id="contact" className="min-h-[100vh] p-4 contact">
        <img
          src={groupImg}
          alt="bg-img"
          className="absolute right-0 top-100 h-[40%] w-full object-cover object-center lg:h-full lg:w-1/2"
        />
        <div className="container mx-auto grid h-full min-h-[100vh] grid-cols-1 lg:grid-cols-5">
          <div className="lg:col-span-3 my-auto">
            <Card className="col-span-12 rounded-xl border border-indigo-50 bg-indigo-50/90 lg:py-20 py-8 px-8 shadow-lg shadow-black/10 backdrop-blur-sm backdrop-saturate-200 xl:col-span-6">
              <div className="mx-auto lg:max-w-xl lg:text-left text-center">
                <Typography
                  variant="h1"
                  color="black"
                  className="mb-4 lg:text-5xl text-3xl"
                >
                  Contactez nous
                </Typography>
                <Typography variant="lead" className="mb-16 !text-indigo-950">
                  Si vous avez des questions ou besoin d'un rendez vous Contactez nous.
                </Typography>
                <form action="#">
                  <div className="mb-4 grid gap-4 grid-cols-2">
                    <Input
                      color="indigo-900"
                      size="lg"
                      label="Prénom"
                      name="first-name"
                      containerProps={{
                        className: "!min-w-full",
                      }}
                    />
                    <Input
                      color="indigo-900"
                      size="lg"
                      label="Nom"
                      name="last-name"
                      containerProps={{
                        className: "!min-w-full",
                      }}
                    />
                  </div>
                  <div className="mb-4">
                    <Input
                      color="indigo-900"
                      type="email"
                      size="lg"
                      label="Email"
                      name="email"
                    />
                  </div>
                  <div className="mb-4">
                    <Input
                      color="indigo-900"
                      size="lg"
                      label="Numéro de Téléphone"
                      name="phone"
                    />
                  </div>
                  <div className="mb-4 h-max">
                    <Textarea
                      color="indigo-900"
                      size="lg"
                      label="Message"
                      name="message"
                    />
                  </div>
                  <Checkbox
                    color="gray"
                    label={
                      <Typography className="font-normal !text-indigo-800">
                        Veuillez acceptez notre {" "}
                        <a
                          href="#"
                          className="font-medium text-indigo-800 hover:text-indigo-950"
                        >
                          Politique de confidentialité
                        </a>
                        .
                      </Typography>
                    }
                    containerProps={{
                      className: "-ml-2.5",
                    }}
                  />
                  <CustomButton size="lg" className="mt-6" fullWidth>
                    Envoyer un message
                  </CustomButton>
                </form>
              </div>
            </Card>
          </div>
        </div>
      </section>
    );
  }
  
  export default ContactSection5;