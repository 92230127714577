import React from "react";
import { Navbar, Collapse, Typography, IconButton } from "@material-tailwind/react";
import { AcademicCapIcon, InformationCircleIcon, ChatBubbleOvalLeftEllipsisIcon, TagIcon } from "@heroicons/react/24/solid";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import CustomButton from './Btn/CustomButton';
import { useLocomotiveScroll } from './LocomotiveScrollProvider ';

function NavItem({ icon, label, onClick }) {
    return (
        <button onClick={onClick} className="flex items-center gap-1.5 p-1 font-normal">
            {icon}
            <Typography as="li" variant="small" color="indigo-950" className="cursor-pointer">
                {label}
            </Typography>
        </button>
    );
}

function NavList({ onNavigate }) {
    return (
        <ul className="mb-4 mt-2 flex flex-col gap-3 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-8">
            <NavItem onClick={() => onNavigate('#about')} icon={<InformationCircleIcon className="h-5 w-5" />} label="À Propos" />
            <NavItem onClick={() => onNavigate('#eduCan')} icon={<AcademicCapIcon className="h-5 w-5" />} label="Éducation Canine" />
            <NavItem onClick={() => onNavigate('#temoignage')} icon={<ChatBubbleOvalLeftEllipsisIcon className="h-5 w-5" />} label="Témoignages" />
            <NavItem onClick={() => onNavigate('#tarif')} icon={<TagIcon className="h-5 w-5" />} label="Tarif" />
        </ul>
    );
}

export function NavbarBlurred() {
    const locomotiveScroll = useLocomotiveScroll();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(!open);

    const handleNavigate = (targetId) => {
        console.log("Locomotive Scroll instance:", locomotiveScroll);
        if (locomotiveScroll && locomotiveScroll.scrollTo) {
          locomotiveScroll.scrollTo(targetId);
        } else {
          console.error("Locomotive Scroll instance is not available or scrollTo is not a function");
        }
      };
      

    return (
        <div className="fixed p-4 z-50 w-full">
            <Navbar className="mx-auto bg-indigo-100/40 backdrop-blur-md">
                <div className="container mx-auto flex items-center justify-between text-indigo-950">
                    <Typography as="a" href="#home" variant="h6" color="indigo-900" className="mr-4 cursor-pointer">
                        Chloé
                    </Typography>
                    <div className="hidden lg:block">
                        <NavList onNavigate={handleNavigate} />
                    </div>
                    <CustomButton onClick={() => handleNavigate('#contact')} size="sm" className="hidden lg:inline-block">
                        Contactez-moi
                    </CustomButton>
                    <IconButton size="sm" variant="text" onClick={handleOpen} className="ml-auto inline-block lg:hidden">
                        {open ? <XMarkIcon className="h-6 w-6" strokeWidth={2} /> : <Bars3Icon className="h-6 w-6" strokeWidth={2} />}
                    </IconButton>
                </div>
                <Collapse open={open}>
                    <NavList onNavigate={handleNavigate} />
                    <CustomButton onClick={() => handleNavigate('#contact')} size="sm" className="mb-2" fullWidth>
                        Contactez-moi
                    </CustomButton>
                </Collapse>
            </Navbar>
        </div>
    );
}

export default NavbarBlurred;
