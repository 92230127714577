import React, { createContext, useContext, useRef, useLayoutEffect } from 'react';
import LocomotiveScroll from 'locomotive-scroll';
import 'locomotive-scroll/src/locomotive-scroll.scss';

const LocomotiveScrollContext = createContext(null);

export const useLocomotiveScroll = () => useContext(LocomotiveScrollContext);

export const LocomotiveScrollProvider = ({ children }) => {
  const scrollRef = useRef(null);
  const locomotiveScrollInstance = useRef(null);

  useLayoutEffect(() => {
    if (scrollRef.current) {
      locomotiveScrollInstance.current = new LocomotiveScroll({
        el: scrollRef.current,
        smooth: true,
      });
      console.log('Locomotive Scroll Initialized:', locomotiveScrollInstance.current);

      return () => {
        if (locomotiveScrollInstance.current) {
          locomotiveScrollInstance.current.destroy();
          console.log('Locomotive Scroll destroyed');
        }
      };
    }
  }, []);

  return (
    <LocomotiveScrollContext.Provider value={locomotiveScrollInstance.current}>
      <div ref={scrollRef} data-scroll-container>
        {children}
      </div>
    </LocomotiveScrollContext.Provider>
  );
};
