import React from "react";
import { Typography, Card, CardBody, Button } from "@material-tailwind/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import CustomButton from "./Btn/CustomButton";

function PricingCard({ title, price, options, color, actionColor, bg, desc }) {
    // Construire dynamiquement les classes en fonction de `bg`
    const titleColorClass = bg ? "text-indigo-50" : "text-indigo-950";
    const descriptionColorClass = bg ? "text-indigo-50" : "text-indigo-950";
    const priceColorClass = bg ? "text-indigo-50" : "text-indigo-950";
    const optionTextColorClass = bg ? "text-indigo-50" : "text-indigo-950";
    const cardBgClass = bg ? "bg-indigo-950 bg-opacity-95" : "";

    return (
        <Card variant="gradient" className={`rounded-lg shadow-xl overflow-hidden ${cardBgClass}`}>
            <CardBody className="relative z-10 p-8">
                <Typography variant="h5" className={`uppercase ${titleColorClass}`}>
                    {title}
                </Typography>
                {bg && (
                    <Button className={`rounded-xl ${actionColor}`}>
                        Populaire
                    </Button>
                )}
                <Typography variant="body1" className={`my-6 ${descriptionColorClass}`}>
                    {desc}
                </Typography>
                <hr className="my-6 border-indigo-100" />
                <ul>
                    {options.map((option, key) => (
                        <li key={key} className={`flex items-center gap-2 ${optionTextColorClass}`}>
                            <CheckIcon className={`h-5 w-5 ${optionTextColorClass}`} />
                            <Typography variant="small" className="text-inherit">
                                {option}
                            </Typography>
                        </li>
                    ))}
                </ul>
                <div className="flex justify-between items-center mt-10">
                    <Typography variant="h3" className={priceColorClass}>
                        {price}
                    </Typography>
                    <Button className={`px-8 py-3 ${actionColor}`}>
                        Rejoindre
                    </Button>
                </div>
            </CardBody>
        </Card>
    );
}


export function PricingSection10() {
    const cards = [
        {
            color: "indigo-50",
            actionColor: "indigo-950",
            bg: false,
            title: "Découverte et Fondamentaux",
            desc: "Une séance individuelle par mois pour établir les bases de l'éducation de votre chien et promouvoir une bonne conduite.",
            price: "60€",
            options: [
                "Évaluation du comportement canin",
                "Apprentissage des commandes de base",
                "Conseils éducatifs personnalisés",
                "Support par courriel continu",
            ],
        },
        {
            color: "indigo-950",
            actionColor: "indigo-50",
            bg: true,
            title: "Évolution Comportementale",
            desc: "Dédié à ceux qui souhaitent un suivi régulier, ce forfait inclut une séance hebdomadaire pour un travail approfondi.",
            price: "299€",
            options: [
                "4 séances d'éducation personnalisées par mois",
                "Techniques d'apprentissage avancées",
                "Accompagnement pour les problématiques comportementales",
                "Support premium par courriel et téléphone",
            ],
        },
        {
            color: "indigo-50",
            actionColor: "indigo-950",
            bg: false,
            title: "Harmonie Familiale",
            desc: "Le forfait le plus complet, offrant un suivi intensif pour une relation harmonieuse et équilibrée avec votre chien.",
            price: "399€",
            options: [
                "6 séances d'éducation personnalisées par mois",
                "Programme de socialisation approfondi",
                "Gestion avancée des comportements complexes",
                "Accès prioritaire pour les rendez-vous",
                "Support premium continu",
            ],
        },
    ];

    return (
        <section id="tarif" className="grid min-h-full place-items-center">
            <div className="container px-4">
                <div className="mx-auto grid max-w-3xl place-items-center py-20 text-center">
                    <Typography className="indigo-950" variant="h2">
                        Nos Formules d'Éducation Canine
                    </Typography>
                    <Typography variant="lead" className="mt-2 !text-indigo-950">
                        Chaque formule comprend un accompagnement personnalisé et un suivi dédié pour assurer le bien-être et le progrès de votre compagnon.
                    </Typography>
                </div>
                <div className="">
                    <div className="mx-auto grid max-w-full gap-8 md:grid-cols-1 lg:grid-cols-3">
                        {cards.map((props, key) => (
                            <PricingCard key={key} {...props} />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PricingSection10;