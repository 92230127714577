import { Typography, Chip } from "@material-tailwind/react";

export function ContentSection7() {
  return (
    <section id="home" className="mx-auto container px-8 lg:py-28 py-10">
      <div className="text-center lg:mb-20 mb-10">
        <Typography variant="h2"  className="text-indigo-950">
          Bienvenue chez Chloé, Éducatrice Canine Passionnée
        </Typography>

        <Typography
          variant="lead"
          color="black"
          className="mt-4 max-w-4xl mx-auto !text-indigo-950"
        >
          Bienvenue sur le site de <strong style={{ className: 'text-indigo-950', fontWeight: 'bold' }}>Chloé</strong>, votre <strong style={{ className: 'text-indigo-950', fontWeight: 'bold' }}>éducatrice canine dévouée</strong>. Avec une approche basée sur la <strong style={{ className: 'text-indigo-950', fontWeight: 'bold' }}>compréhension et le respect mutuel</strong> entre vous et votre compagnon à quatre pattes, Chloé vous propose des <strong style={{ className: 'text-indigo-950', fontWeight: 'bold' }}>solutions sur mesure</strong> pour une cohabitation harmonieuse. Forte d'une <strong style={{ className: 'text-indigo-950', fontWeight: 'bold' }}>expérience solide</strong> et d'une <strong style={{ className: 'text-indigo-950', fontWeight: 'bold' }}>passion inébranlable pour les animaux</strong>, elle vous guide vers une relation équilibrée et enrichissante avec votre chien.
        </Typography>
      </div>
      <div className="mb-6 grid grid-cols-2 gap-6 md:grid-cols-12">
        <div className="col-span-2 h-[140px] sm:h-[150px] md:col-span-4">
          <img
            src="https://images.unsplash.com/photo-1530281700549-e82e7bf110d6?q=80&w=3776&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="photos"
            className="h-full w-full rounded-lg shadow-md object-cover object-center"
          />
        </div>
        <div className="col-span-1 h-[100px] sm:h-[150px] md:col-span-3">
          <img
            src="https://images.unsplash.com/photo-1587300003388-59208cc962cb?q=80&w=3870&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="photos"
            className="h-full w-full rounded-lg shadow-md object-cover object-center"
          />
        </div>
        <div className="col-span-1 h-[100px] sm:h-[150px] md:col-span-5">
          <img
            src="https://images.unsplash.com/photo-1568393691622-c7ba131d63b4?q=80&w=3862&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="photos"
            className="h-full w-full rounded-lg shadow-md object-cover"
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-6 md:grid-cols-12">
        <div className="col-span-2 h-[140px] sm:h-[150px] md:col-span-3">
          <img
            src="https://images.unsplash.com/photo-1544567708-827a79119a78?q=80&w=3774&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="photos"
            className="h-full w-full rounded-lg shadow-md object-cover"
          />
        </div>
        <div className="col-span-1 h-[100px] sm:h-[150px] md:col-span-5">
          <img
            src="https://images.unsplash.com/photo-1621010312435-a9d358e69623?w=1000&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8YmVyZ2VyJTIwYXVzdHJhbGllbnxlbnwwfDB8MHx8fDA%3D"
            alt="photos"
            className="h-full w-full rounded-lg shadow-md object-cover"
          />
        </div>
        <div className="col-span-1 h-[100px] sm:h-[150px] md:col-span-4">
          <img
            src="https://plus.unsplash.com/premium_photo-1667673941713-ad4d4751c93b?w=1000&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mzd8fGNoaWVufGVufDB8MHwwfHx8MA%3D%3D"
            alt="photos"
            className="h-full w-full rounded-lg shadow-md object-cover"
          />
        </div>
      </div>
    </section>
  );
}

export default ContentSection7;