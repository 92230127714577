import { Typography, Button } from "@material-tailwind/react";
import MikeyImage  from "../img/Mikey.jpg";


export function ContentSection5() {
    return (
        <section id='about' className="py-10 px-8 min-h-screen grid">
            <div className="grid max-w-7xl mx-auto my-auto items-center place-items-center lg:grid-cols-2 gap-10">
                <div className="lg:max-w-md">
                    <Typography
                        variant="h2"
                        color="indigo-950"
                        className="mb-6 lg:text-3xl !text-2xl !leading-snug lg:max-w-sm"
                    >
                        À propos de Chloé
                    </Typography>
                    <Typography className="text-base !font-normal mb-12 !text-indigo-950">
                        L'éducation canine, telle que je la conçois, ne se limite pas à des commandes et à des exercices. C'est une aventure partagée, un dialogue continu entre l'animal et son entourage humain. Passionnée depuis ma plus tendre enfance par le comportement animal, je me suis dédiée à comprendre la langue des chiens pour créer des ponts de compréhension avec leurs familles. Pour moi, l'éducation canine est avant tout une question d'harmonie et de bonheur partagé.
                        <br />
                        <br />
                        Chaque famille est unique, tout comme chaque chien l'est. Mon objectif est de vous aider à tisser des liens solides basés sur la confiance et le respect mutuel. Grâce à des méthodes douces, personnalisées et ludiques, j'aspire à rendre chaque interaction avec votre chien aussi enrichissante que possible. J'accorde une importance particulière à l'ajustement des techniques d'éducation en fonction de la personnalité de votre compagnon à quatre pattes, toujours avec l'idée de promouvoir son bien-être et sa joie de vivre.
                    </Typography>
                    <Typography className="text-base !font-normal !text-indigo-950">
                        Rendre les familles et leurs chiens heureux, c'est le cœur de ma mission. Je m'engage à offrir un accompagnement sur mesure qui respecte l'intégrité de chaque chien et vise à renforcer le lien spécial qui vous unit. Votre bien-être et celui de votre compagnon sont au centre de ma pratique, et c'est avec cet engagement que je vous guide vers une cohabitation sereine et joyeuse.
                    </Typography>
                </div>
                <div>
                    <img
                        src={`https://images.unsplash.com/photo-1615663482725-e4f675183c03?w=1000&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fG1hbGlub2lzfGVufDB8fDB8fHww`}
                        alt="team work"
                        className="mb-6 h-full lg:h-[370px] w-full shadow-lg rounded-xl object-cover object-center"
                    />
                    <img
                        src={MikeyImage}
                        alt="team work"
                        className="h-full lg:h-[370px] w-full shadow-lg rounded-xl object-cover object-center"
                    />
                </div>
            </div>
        </section>
    );
}

export default ContentSection5;