import React from 'react';
import { Button } from '@material-tailwind/react';

const CustomButton = ({ children, ...props }) => {
  // Appliquez la couleur personnalisée directement ou via une classe Tailwind si vous l'avez configurée dans tailwind.config.js
  return (
    <Button
      {...props}
      style={{ backgroundColor: '#1e1b4b', borderColor: '#1e1b4b', ...props.style }} // Assurez-vous que les props.style supplémentaires ne soient pas écrasés
      className={`text-indigo-50 ${props.className}`} // Conserve la possibilité de passer des classes supplémentaires
    >
      {children}
    </Button>
  );
};

export default CustomButton;
