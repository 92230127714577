import { UserIcon } from "@heroicons/react/24/solid";
import {
  Card,
  CardBody,
  Typography,
  Avatar,
  CardHeader,
  IconButton,
} from "@material-tailwind/react";

function TestimonialCard({ img, feedback, client, title }) {
  return (
    <Card shadow={false} className="items-center text-center bg-indigo-100">
      <CardHeader shadow={false} floated={false}>
          <img src={img} alt={client} className="w-20 h-20" />
      </CardHeader>
      <CardBody>
        <Typography variant="h5" color="indigo-950" className="font-medium text-indigo-950">
          {client}
        </Typography>
        <Typography
          variant="small"
          className="mb-4 mt-1 font-medium !text-indigo-950"
        >
          {title}
        </Typography>
        <Typography
          variant="paragraph"
          className="mb-5 font-normal !text-indigo-950"
        >
          &quot;{feedback}&quot;
        </Typography>
      </CardBody>
    </Card>
  );
}

const testimonials = [
  {
    feedback:
      "Chloé a non seulement enseigné à notre terrier Jack des commandes essentielles, mais elle a aussi renforcé notre lien avec lui. Elle ne s'est pas contentée de nous guider, elle a marché à nos côtés, nous permettant de comprendre les besoins uniques de Jack et d'y répondre avec amour et respect.",
    client: "Sophie Bernard",
    title: "Vétérinaire",
    img: "https://www.material-tailwind.com/img/avatar1.jpg",
  },
  {
    feedback:
      "Face aux défis que nous posait le comportement de notre labrador, Chloé a été l'optimiste qui a su voir le potentiel de changement et le réaliste qui nous a appris à ajuster notre quotidien pour y arriver. Ses conseils nous ont permis de vivre une véritable transformation.",
    client: "Lucas Martin",
    title: "Enseignant",
    img: "https://www.material-tailwind.com/image/avatar4.jpg",
  },
  {
    feedback:
      "Choisir une voie différente pour l'éducation de notre chien semblait risqué, mais Chloé nous a montré comment créer notre propre chemin avec assurance et bienveillance. Les résultats sont là : un chien heureux et une famille épanouie.",
    client: "Émilie Durand",
    title: "Directrice des Ressources Humaines",
    img: "https://www.material-tailwind.com/image/avatar8.svg",
  },
];

export function TestimonialSection6() {
  return (
    <section id="temoignage" className="px-8 py-10 lg:py-28">
      <div className="container mx-auto">
        <div className="mb-20 text-center">
          <IconButton className="bg-indigo-950" size="lg">
            <UserIcon className="h-6 w-6 bg-indigo-950" />
          </IconButton>
          <Typography
            variant="h2"
            //color="indigo-950"
            className="mb-4 mt-10 text-3xl lg:text-5xl text-indigo-950"
          >
            Ce que disent nos clients
          </Typography>
          <Typography
            variant="lead"
            className="mx-auto max-w-3xl !text-indigo-950"
          >
            Nos clients sont notre fierté et leur satisfaction est notre objectif. Chaque progression est un pas de plus vers la réalisation d'un rêve partagé.
          </Typography>
        </div>
        <div className="grid gap-x-8 gap-y-6 md:grid-cols-2 lg:grid-cols-3">
          {testimonials.map((props, key) => (
            <TestimonialCard key={key} {...props} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default TestimonialSection6;