import React, { useRef, useLayoutEffect, useEffect } from 'react';
import LocomotiveScroll from 'locomotive-scroll';
import 'locomotive-scroll/src/locomotive-scroll.scss';
import AccueilSection from "./components/AccueilSection";
import Navbar from "./components/NavbarBlurred";
import Contact from "./components/Contact";
import AboutSection from "./components/AboutSection";
import TestimonialsSection from "./components/TestimonialsSection";
import Tarif from "./components/Tarif"
import { LocomotiveScrollProvider } from './components/LocomotiveScrollProvider ';

export default function App() {
  const scrollRef = useRef(null);
  const locomotiveScrollInstance = useRef(null);  

  return (

    <LocomotiveScrollProvider>
      <Navbar />
      <div data-scroll-section>
        <AccueilSection />
      </div>
      <div data-scroll-section>
        <AboutSection />
      </div>
      <div data-scroll-section>
        <TestimonialsSection />
      </div>
      <div data-scroll-section>
        <Tarif />
      </div>
      <div data-scroll-section>
        <Contact />
      </div>
    </LocomotiveScrollProvider>
  );
}